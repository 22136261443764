import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Card, Row, Col } from 'react-bootstrap';
import "./Main.css";

let cardWidth = '18rem';
let cardHeight = '36rem';

const AboutUs = () => {
    return (
        <>
        <div className="mx-3 my-5">
            <h1 className="text-center">
                Learn a little bit more about the team.<br/>
                
            </h1>
            <hr className="hr hr-blurry" />
            <Row className="justify-content-md-center mt-3">
                <Col md="auto">
                    <Card style={{ width: cardWidth, height: cardHeight}}>
                            <Card.Header className="text-center"><h3>Weston Wood</h3></Card.Header>
                            <Card.Body>
                                <Card.Link href="https://211la.org/">
                                    <Card.Img variant="top" src="https://cdn.vectorstock.com/i/1000v/66/13/default-avatar-profile-icon-social-media-user-vector-49816613.jpg" />
                                </Card.Link>
                                <hr className="hr hr-blurry" />
                                <Card.Text className="text-center"><p>
                                    Info about Weston needs to be added here.
                                </p></Card.Text>
                            </Card.Body>
                    </Card>
                </Col>
                <Col md="auto">
                    <Card style={{ width: cardWidth, height: cardHeight}}>
                            <Card.Header className="text-center"><h3>Cem Teker</h3></Card.Header>
                            <Card.Body>
                                <Card.Link href="https://211la.org/">
                                    <Card.Img variant="top" src="https://cdn.vectorstock.com/i/1000v/66/13/default-avatar-profile-icon-social-media-user-vector-49816613.jpg" />
                                </Card.Link>
                                <hr className="hr hr-blurry" />
                                <Card.Text className="text-center"><p>
                                    Info about Cem needs to be added here.
                                </p></Card.Text>
                            </Card.Body>
                    </Card>
                </Col>
                <Col md="auto">
                    <Card style={{ width: cardWidth, height: cardHeight}}>
                            <Card.Header className="text-center"><h3>Kaelyn Taing</h3></Card.Header>
                            <Card.Body>
                                <Card.Link href="https://211la.org/">
                                    <Card.Img variant="top" src="https://cdn.vectorstock.com/i/1000v/66/13/default-avatar-profile-icon-social-media-user-vector-49816613.jpg" />
                                </Card.Link>
                                <hr className="hr hr-blurry" />
                                <Card.Text className="text-center"><p>
                                    Info about Kaelyn needs to be added here.
                                </p></Card.Text>
                            </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="justify-content-md-center mt-3">
                <Col md="auto">
                    <Card style={{ width: cardWidth, height: cardHeight}}>
                            <Card.Header className="text-center"><h3>Vincent Rodriguez</h3></Card.Header>
                            <Card.Body>
                                <Card.Link href="https://211la.org/">
                                    <Card.Img variant="top" src="https://cdn.vectorstock.com/i/1000v/66/13/default-avatar-profile-icon-social-media-user-vector-49816613.jpg" />
                                </Card.Link>
                                <hr className="hr hr-blurry" />
                                <Card.Text className="text-center"><p>
                                    Info about Vincent needs to be added here.
                                </p></Card.Text>
                            </Card.Body>
                    </Card>
                </Col>
                <Col md="auto">
                    <Card style={{ width: cardWidth, height: cardHeight}}>
                            <Card.Header className="text-center"><h3>Ryan Kwan</h3></Card.Header>
                            <Card.Body>
                                <Card.Link href="https://211la.org/">
                                    <Card.Img variant="top" src="https://cdn.vectorstock.com/i/1000v/66/13/default-avatar-profile-icon-social-media-user-vector-49816613.jpg" />
                                </Card.Link>
                                <hr className="hr hr-blurry" />
                                <Card.Text className="text-center"><p>
                                    Info about Ryan needs to be added here.
                                </p></Card.Text>
                            </Card.Body>
                    </Card>
                </Col>
                <Col md="auto">
                    <Card style={{ width: cardWidth, height: cardHeight}}>
                            <Card.Header className="text-center"><h3>Salvador Gonzalez</h3></Card.Header>
                            <Card.Body>
                                <Card.Link href="https://211la.org/">
                                    <Card.Img variant="top" src="https://cdn.vectorstock.com/i/1000v/66/13/default-avatar-profile-icon-social-media-user-vector-49816613.jpg" />
                                </Card.Link>
                                <hr className="hr hr-blurry" />
                                <Card.Text className="text-center"><p>
                                    Info about Salvador needs to be added here.
                                </p></Card.Text>
                            </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="justify-content-md-center mt-3">
                <Col md="auto">
                    <Card style={{ width: cardWidth, height: cardHeight}}>
                            <Card.Header className="text-center"><h3>Jose Escobar</h3></Card.Header>
                            <Card.Body>
                                <Card.Link href="https://211la.org/">
                                    <Card.Img variant="top" src="https://cdn.vectorstock.com/i/1000v/66/13/default-avatar-profile-icon-social-media-user-vector-49816613.jpg" />
                                </Card.Link>
                                <hr className="hr hr-blurry" />
                                <Card.Text className="text-center"><p>
                                    Info about Jose needs to be added here.
                                </p></Card.Text>
                            </Card.Body>
                    </Card>
                </Col>
                <Col md="auto">
                    <Card style={{ width: cardWidth, height: cardHeight}}>
                            <Card.Header className="text-center"><h3>Adam Dixon</h3></Card.Header>
                            <Card.Body>
                                <Card.Link href="https://211la.org/">
                                    <Card.Img variant="top" src="https://cdn.vectorstock.com/i/1000v/66/13/default-avatar-profile-icon-social-media-user-vector-49816613.jpg" />
                                </Card.Link>
                                <hr className="hr hr-blurry" />
                                <Card.Text className="text-center"><p>
                                    Info about Adam needs to be added here.
                                </p></Card.Text>
                            </Card.Body>
                    </Card>
                </Col>
                <Col md="auto">
                    <Card style={{ width: cardWidth, height: cardHeight}}>
                            <Card.Header className="text-center"><h3>Kyle Chau</h3></Card.Header>
                            <Card.Body>
                                <Card.Link href="https://211la.org/">
                                    <Card.Img variant="top" src="https://cdn.vectorstock.com/i/1000v/66/13/default-avatar-profile-icon-social-media-user-vector-49816613.jpg" />
                                </Card.Link>
                                <hr className="hr hr-blurry" />
                                <Card.Text className="text-center"><p>
                                    Info about Kyle needs to be added here.
                                </p></Card.Text>
                            </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="justify-content-md-center mt-3">
                <Col md="auto">
                    <Card style={{ width: cardWidth, height: cardHeight}}>
                            <Card.Header className="text-center"><h3>Uriel Baldesco</h3></Card.Header>
                            <Card.Body>
                                <Card.Link href="https://211la.org/">
                                    <Card.Img variant="top" src="https://cdn.vectorstock.com/i/1000v/66/13/default-avatar-profile-icon-social-media-user-vector-49816613.jpg" />
                                </Card.Link>
                                <hr className="hr hr-blurry" />
                                <Card.Text className="text-center"><p>
                                    Info about Uriel needs to be added here.
                                </p></Card.Text>
                            </Card.Body>
                    </Card>
                </Col>
                <Col md="auto">
                    <Card style={{ width: cardWidth, height: cardHeight}}>
                            <Card.Header className="text-center"><h3>Darius Ayvazian</h3></Card.Header>
                            <Card.Body>
                                <Card.Link href="https://211la.org/">
                                    <Card.Img variant="top" src="https://cdn.vectorstock.com/i/1000v/66/13/default-avatar-profile-icon-social-media-user-vector-49816613.jpg" />
                                </Card.Link>
                                <hr className="hr hr-blurry" />
                                <Card.Text className="text-center"><p>
                                    Info about Darius needs to be added here.
                                </p></Card.Text>
                            </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>    
        </>)
}

export default AboutUs;
